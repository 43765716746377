@import './styles/reset.css';
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root main{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

a:hover {
  text-decoration: underline;
}

:root {
  --fullBackgroundColor: white;
  --backgroundColor: #ebebeb;

  /* SHOWATT */

  --color-primary: #2B805E;
  --color-primary-light: #87C0A7;
  --color-primary-very-light: #E4F1EB;
  --color-secondary: #E39063;
  --color-secondary-very-light: #F6DACB;
  --color-text: #000000;
  --color-accent: #240035;

  --results-bg: var(--color-primary-light);
  --results-section-bg: var(--color-primary);
  --results-bullet-point: var(--color-primary);
  --bg-score: var(--color-primary-light);
  --reports-bg: var(--yellow);

  --bg-blockAccent: var(--color-primary-light);
  --bg-feedback: var(--color-secondary);
  --text-feedback: white;
  --completion-bar: var(--color-primary);
  --projectCard-borderColor: var(--color-secondary);

  --buttonShadow: transparent;
  --sortButton-borderBottom-hover: var(--color-primary);
  --sortButton-borderBottom-selected: var(--color-secondary);

  --model1-primary: #A09CB0;
  --model2-primary: #A3B9C9;

  --grey: #b5b5b5;
  --brown: #a55e47;
  --pink: #ffa9c4;
  --red: #ff5b17;
  --orange: #ff9229;
  --purple: #8746ae;
  --lightpurple: #cea8ff;
  --blue: #0b67a9;
  --lightblue: #afd4ff;
  --brightgreen: #0f9324;
  --brightlightgreen: #81efc8;
}

html {
  scroll-behavior: smooth;
}

#root {
  background-color: var(--backgroundColor);
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  background-color: var(--backgroundColor);
  color: #060606;
  align-items: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.h1b {
  font-weight: bold;
  font-size: 39px;
}
.h1r {
  font-weight: normal;
  font-size: 39px;
}
.h2b {
  font-weight: bold;
  font-size: 30px;
}
.h2r {
  font-weight: normal;
  font-size: 30px;
}
.h3r {
  font-weight: normal;
  font-size: 25px;
}

.h3b {
  font-weight: bold;
  font-size: 25px;
}
.h4r {
  font-weight: normal;
  font-size: 20px;
}
.h4b {
  font-weight: bold;
  font-size: 20px;
}
.h5r {
  font-weight: normal;
  font-size: 18px;
}
.h5b {
  font-weight: bold;
  font-size: 18px;
}
.h6r {
  font-weight: normal;
  font-size: 16px;
}
.h6b {
  font-weight: 600;
  font-size: 16px;
}
.hxb {
  font-weight: 600;
  font-size: 14px;
}
.hxc {
  font-weight: 500;
  font-size: 14px;
}
.hxr {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.hzb {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
}
.hzr {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.underline {
  text-decoration: underline;
}

.SnackbarContainer-top {
  top: 64px !important;
  z-index: 12000 !important;
}

.even-row {
  background-color: #DAE7E3;
}
.odd-row {
  background-color: #F3F7F6;
}
#equipment_table .MuiOutlinedInput-root input {
  background-color: white;
}